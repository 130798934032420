$bold:      'Bold';
$medium:    'Medium';
$regular:   'Regular';
$slim:      'Light';

.h1 {
   font-size: 3rem;
}
.h2 {
   font-size: 2.25rem;
}
.h3, .h3-r {
   font-size: 2rem;
}
.h4, .h4-r {
   font-size: 1.63rem;
}
.h5, .h5-r {
   font-size: 1.38rem;
}