@font-face {
   font-family: 'Bold';
   src: url('../../fonts/Poppins-Bold.woff2') format('woff2'),
       url('../../fonts/Poppins-Bold.woff') format('woff');
   font-weight: bold;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'Light';
   src: url('../../fonts/Poppins-Light.woff2') format('woff2'),
       url('../../fonts/Poppins-Light.woff') format('woff');
   font-weight: 300;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'Medium';
   src: url('../../fonts/Poppins-Medium.woff2') format('woff2'),
       url('../../fonts/Poppins-Medium.woff') format('woff');
   font-weight: 500;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'Regular';
   src: url('../../fonts/Poppins-Regular.woff2') format('woff2'),
       url('../../fonts/Poppins-Regular.woff') format('woff');
   font-weight: normal;
   font-style: normal;
   font-display: swap;
}