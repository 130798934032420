/* You can add global styles to this file, and also import other style files */
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

@import "assets/scss/abstracts/all";
@import "assets/scss/base/all";
@import "assets/scss/template/all";
/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

body {
   font-family: $regular;
}