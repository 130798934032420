.main-card {
   width: 100%;
   border-radius: 20px;
   &__img {
      position: relative;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      width: 100%;
      height: 350px;
      border-radius: 20px 20px 0 0;
      position: relative;
      &::after {
         content: '';
         background-color: rgba(#000, 0.4);
         border-radius: 20px 20px 0 0;
         position: absolute;
         width: 100%;
         height: 100%;
         top: 0;
         left: 0;
         z-index: 1;
      }
      .img-content {
         position: absolute;
         bottom: 28px;
         left: 28px;
         z-index: 2;
         &__text {
            color: $white;
            text-transform: uppercase;
            margin-bottom: 0;
            font-size: 14px;
            letter-spacing: 1.5px;
         }
         &__value {
            color: $white;
            font-family: $bold;
            letter-spacing: 1.5px;
            font-size: 2.25rem;
            margin-bottom: 0;
            @media (max-width: 1100px) {
               font-size: 2rem;
            }
         }
      }
   }
   &__body {
      border-radius: 0 0 20px 20px;
      border-right: 1px solid $gray06;
      border-left: 1px solid $gray06;
      border-bottom: 1px solid $gray06;
      padding: 28px 28px 30px;

      .body-badge {
         background-color: $primary;
         border-radius: 50px;
         color: $white;
         text-transform: uppercase;
         font-size: 12px;
         padding: 4px 8px;
      }

      .body-title {
         margin-top: 10px;
         font-family: $bold;
         font-size: 26px;
      }
      .body-text {
         font-family: $regular;
         font-size: 14px;
      }
   }
}