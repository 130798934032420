.modal {
   .modal-dialog {
      .btn-close {
         padding: 40px 20px 0;
         outline: none;
         box-shadow: none;
      }
      .modal-content {
         border: 0px;
         border-radius: 18px;

         .modal-body {
            padding: 80px;
   
            &__title {
               font-family: $bold;
               text-align: center;
               text-transform: uppercase;
               margin-bottom: 40px;
            }
         }
      }
   }
}