$white:  	#FFF;
$white02: 	#F9F9F9;
$white03: 	#F4F4F4;
$white04: 	#F0F0F0;
$white05: 	#EFF2F3;
$white06: 	#ECF0F1;

$gray:      #53565A;
$gray02:    #868A8A;
$gray03:    #768692;
$gray04:    #90A2B1;
$gray05:    #A6BACB;
$gray06:    #C9C9C9;

$black:  	#000000;
$black02: 	#222222;
$black03: 	#333333;

$primary:         #50D890;
$primary-hover:   #42b879;
$secondary:       #4F98CA;
$secondary-hover: #3d789f;