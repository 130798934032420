button.swal2-confirm.swalConfirmButton.swal2-styled {
	// width: 35%;
	// color: $white;
	// border-radius: 10px;
	font-weight: bold;
	min-height: 34px;
	padding: 0 1rem;
	font-size: 0.87rem;
	// background-color: $primary;
}

button.swal2-deny.swalDennyButton.swal2-styled {
	// width: 35%;
	// color: $white;
	// border-radius: 10px;
	font-weight: bold;
	min-height: 34px;
	padding: 0 1rem;
	font-size: 0.87rem;
	// background-color: $danger;
}

.buttons {
	border-radius: 8px;
	background-color: transparent;
	border: 2px solid transparent;
	border-radius: 100px;
	cursor: pointer;
	display: flex;
	align-items: center;
	font-size: 0.875rem; //14px
	padding: 12px 30px;
	letter-spacing: 1px;
	text-decoration: none !important;
	text-transform: uppercase;
	transition: .3s ease-in-out;
	margin-bottom: 0;
	&.buttons-primary {
      background-color: $primary;
		color: $white;
		font-family: $medium;
		&:hover {
			background-color: $primary-hover;
		}
		i, svg {
			margin-right: 6px;
			margin-top: 2px;
			@media (max-width: 768px) {
				 display: none;
			}
	  }
	}
	&.buttons-secondary {
		background-color: $secondary;
		font-family: $medium;
		color: $white;
		&:hover {
			background-color: $secondary-hover;
      }
		i, svg {
			margin-right: 6px;
			margin-top: 2px;
			@media (max-width: 768px) {
				 display: none;
			}
	  }
	}
	&.buttons-back {
		border: 2px solid $gray;
		font-family: $medium;
		color: $gray;
		i, svg {
			margin-right: 6px;
			margin-top: 2px;
			@media (max-width: 768px) {
				 display: none;
			}
	  }
	}
	// &.buttons-delete {
	// 	&:hover {
         
   //    }
	// }
}

.circle-button {
	border-radius: 20px;
	background-color: transparent;
	border: 2px solid transparent;
	border-radius: 100px;
	cursor: pointer;
	font-size: 0.8125rem; //13px
	padding: 15px 15px;
	height: 30px;
	width: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none !important;
	transition: .3s ease-in-out;

	&.button-main-primary {
		background-color: $primary;
		color: $white;
		font-weight: 700;
		@media (max-width: 768px) {
			font-weight: 500;
		}
		&:hover {
			transition: .2s ease-in-out;
			background-color: $primary-hover;
		}
		i, svg {
			margin-left: 6px;
			margin-top: 2px;
			@media (max-width: 768px) {
				display: none;
			}
		}
	}

	&.button-primary {
		background-color: $primary;
		color: $white;
		font-weight: 700;
		@media (max-width: 768px) {
			font-weight: 500;
		}
		&:hover {
			transition: .2s ease-in-out;
			background-color: $secondary;
		}
	}

	&.button-secondary {
		background-color: $secondary;
		color: $white;
		font-weight: 700;
		@media (max-width: 768px) {
			font-weight: 500;
		}
		&:hover {
			transition: .2s ease-in-out;
			background-color: $primary;
		}
	}

	&.button-delete {
		background-color: rgba(red, 0.5);
		color: $white;
		font-weight: 700;
		@media (max-width: 768px) {
			font-weight: 500;
		}
		&:hover {
			transition: .2s ease-in-out;
			background-color: rgba(red, 1);
		}
	}

	&.button-outline-primary {
		border: 2px solid $primary;
		color: $primary;
		font-weight: 600;
		@media (max-width: 768px) {
			font-weight: 500;
		}
		&:hover {
			border: 2px solid transparent;
			color: $white;
			background-color: $primary;
		}
	}

	&.button-outline-secondary {
		 border: 2px solid $secondary;
		 color: $secondary;
		 font-weight: 600;
		 @media (max-width: 768px) {
			  font-weight: 500;
		 }
		 &:hover {
			  border: 2px solid transparent;
			  color: $white;
			  background-color: $secondary;
		 }
	}


	&.button-cancel {
		background-color: $gray;
		color: $white;
		font-weight: 700;
		@media (max-width: 768px) {
			font-weight: 500;
		}
		&:hover {
			transition: .2s ease-in-out;
			background-color: $primary;
		}
	}
}