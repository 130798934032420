.main-table {
   thead {
      tr {
         background-color: $primary;           
         th {
            padding: 20px;
            text-transform: uppercase;
            font-size: 0.75rem;
            color: $white;
            font-family: $medium;
            border: 0;
         }
      }
   }
   tbody {
      tr {
         cursor: pointer;
         // &:last-child {
         //    border-bottom: 1px solid rgba($gray, 0.3);
         // }
         td {
            font-size: 13px;
            color: $gray02;
				height: 60px;
            // &:last-child {
            //    border-bottom: 1px solid rgba($gray, 0.3);
            // }
				@media (max-width: 768px) {
               height: 88px;
            }
            &::first-letter {
               text-transform: uppercase;
            }
         }
      }
  }
}