.form-item {
   margin-top: 1em;
   margin-bottom: 1.4em;
   position: relative;

   label {
      color: $black03;
      display: inline-block;
      margin-bottom: 0.3rem;
      font-family: $regular;
      font-size: 14px;
   }

   .form-text {
      background-color: $white02;
      height: auto;
      border: 0;
      border-radius: 30px;
      box-shadow: 0px 0px 5px rgba($black, 0.25);
      padding: 15px 30px;
      width: 100%;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: $gray03;
      &.disabled {
         background-color: rgba($gray, 0.2);
         pointer-events:none;
      }
      &:disabled {
         background-color: rgba($gray, 0.2);
         pointer-events:none;
      }
      &::placeholder {
         font-size: 13px;
      }
   }

   .errors {
      color: red;
      font-family: $bold;
      font-size: 0.6875rem;
   }
}